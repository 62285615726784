import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import { BasicSelect } from 'vue-search-select';
import InvoiceDetails from '../invoiceDetails';
import DatePicker from 'vue2-datepicker';
import URL_UTILITY from '../../../../utility/url.utility';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'addEditEwayBill',
  // props: { propData: Object, dataFlag: Boolean },
  props: ['propData', 'dataFlag', 'invoiceNo'],
  components: {
    commonHelper,
    appStrings,
    DatePicker,
    BasicSelect,
    InvoiceDetails
  },
  watch: {},
  data() {
    return {
      currentPage: 1,
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showValueSetModal: false,
      showInvoiceDetailModal: false,
      sourceName: { text: 'FA' },
      dropDownSourceList: [{ text: 'FA' }, { text: 'INV' }],
      invoiceNumber: null,
      invoiceId: null,
      invoiceDate: {},
      transporter: {
        name: null,
        id: null
      },
      transportationMode: {
        text: 'Road',
        value: 1
      },
      distance: 0,
      lrNum: null,
      lrDate: '',
      fpGstin: null,
      tpGstin: null,
      billFromAdd: null,
      billToAdd: null,
      irnNumber: null,
      numberType: 'SINGLE',
      multiNumber: null,
      vehicalType: {
        text: 'Regular',
        value: 'R'
      },
      vehicleNo: null,
      transportDate: null,
      validFromDate: null,
      validUntilDate: null,
      supplier: null,
      customer: '',
      billToAddress1: null,
      billToAddress_2: null,
      billToStateCode: null,
      billToCity: null,
      billToPincode: null,
      supplierAddress1: null,
      supplierAddress2: null,
      supplierCity: null,
      supplierPincode: null,
      supplierStateCode: null,
      gebId: 0,
      active: true,
      generateEwayBillFlag: true,
      cancelEwayBillFlag: true,
      ewayBillData: [],
      ewayBillFields: [
        {
          key: 'selectRadio',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'ewb_no',
          label: 'Eway Bill Num'
        },
        {
          key: 'invoice_date',
          label: 'Date'
        },
        {
          key: 'ewb_valid_from',
          label: 'Valid From'
        },
        {
          key: 'ewb_valid_to',
          label: 'Valid Until'
        },
        {
          key: 'trans_name'
        },
        {
          key: 'trans_mode'
        },
        {
          key: 'distance'
        },
        {
          key: 'trans_doc_dt'
        },
        {
          key: 'trans_doc_no',
          label: 'LR Number'
        },
        {
          key: 'veh_no'
        },
        {
          key: 'veh_type'
        },
        {
          key: 'remarks'
        },
        {
          key: 'status'
        },
        {
          key: 'cancel_status'
        },
        {
          key: 'cancel_date'
        },
      ],
      ewayBillNo: null,
      remarks: '',
      reqId: null,
      dowloadReport: URL_UTILITY.getDownloadReportUrl
    };
  },validations: {
    transporter: {
      id: {
        required
      },
      name:{
        required
      }
    },
    distance:{required},
    lrDate:{required},
    lrNum:{required},
    vehicleNo:{required},


  },
  mounted() {
    if (this.propData && this.propData.invoiceId != null && !this.dataFlag) {
      this.getEwayBillDetails(this.propData);
    }
    this.invoiceNumber = this.invoiceNo;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.invoiceNumber) {
          this.addEditEwayBill();
        }
        if (actionName === 'download' && this.ewayBillData[0].status!=="cancelled") {
          this.downloadLog();
        }
        if(actionName=== 'print'&& this.ewayBillData[0].status!=="cancelled"){
          this.printReport()
        }
      }
    });
  },
  methods: {
    downloadLog() {
      if(this.reqId==null){
        alert("Please print report first")
      }
      else{
        window.open(
          this.dowloadReport + '/' + this.reqId,
          '_blank'
        );
      }
   
    },
    getInvoiceNumber() {
      this.showInvoiceDetailModal = true;
    },
    generateEwayBill() {
      this.loader = true;
      this.$store
        .dispatch('assets/generateEwayBill', this.gebId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getEwayBillDetails({
              sourceName: this.sourceName.text,
              invoiceId: this.invoiceId
            });
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addEditEwayBill() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
      const payload = {
        source: this.sourceName.text,
        invoice_id: this.invoiceId,
        invoice_num: this.invoiceNumber,
        invoice_date: this.invoiceDate,
        irn: this.irnNumber,
        trans_id: this.transporter.id,
        trans_name: this.transporter.name,
        distance: this.distance,
        trans_doc_dt: commonHelper.formattedDate(this.lrDate),
        trans_doc_no: this.lrNum,
        trans_mode: this.transportationMode.value,
        veh_type: this.vehicalType.value,
        veh_no: this.vehicleNo,
        customer: this.customer,
        geb_id: this.gebId,
        bill_to_address_1: this.billToAddress1,
        bill_to_address_2: this.billToAddress_2,
        bill_to_state_code: this.billToStateCode,
        bill_to_city: this.billToCity,
        bill_to_pincode: this.billToPincode,
        supplier: this.supplier,
        supplier_address_1: this.supplierAddress1,
        supplier_address_2: this.supplierAddress2,
        supplier_state_code: this.supplierStateCode,
        supplier_city: this.supplierCity,
        supplier_pincode: this.supplierPincode,
        fp_gstin: this.fpGstin
      };
      this.loader = true;
      this.$store
        .dispatch('assets/addEditEwayBill', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getEwayBillDetails({
              sourceName: this.sourceName.text,
              invoiceId: this.invoiceId
            });
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
      }
    },
    cancelEwayBill() {
      const payload = {
        ewb_no: this.ewayBillNo,
        fp_gstin: this.fpGstin,
        remarks: this.remarks
      };
      this.loader = true;
      this.$store
        .dispatch('assets/cancelEwayBill', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getEwayBillDetails({
              sourceName: this.sourceName.text,
              invoiceId: this.invoiceId
            });
          } else {
            this.loader = false;
            alert('Something Went Wrong');
          }
        })
        .catch(error => {
          this.loader = false;
          this.responseMsg = error;
        });
    },
    getEwayBillDetails(item) {
      const payload = {
        source: item.sourceName,
        invoice_id: item.invoiceId
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getEwayBillDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.ewayBillData = response.data.data;
            this.ewayBillData[0].selectRadio = true;
            this.ewayBillData[0].trans_mode="Road"
            this.ewayBillData[0].veh_type="Regular"
            // this.lrNum = this.ewayBillData[0].trans_doc_no;
            // this.lrDate = new Date(this.ewayBillData[0].trans_doc_dt);
            this.transporter.id = this.ewayBillData[0].trans_id;
            this.transporter.name = this.ewayBillData[0].trans_name;
            this.distance = this.ewayBillData[0].distance;
            this.transportationMode.value = this.ewayBillData[0].trans_mode;
            this.transportationMode.text = 'Road';
            this.vehicalType.value = this.ewayBillData[0].veh_type;
            this.vehicalType.text = 'Regular';
            this.vehicleNo = this.ewayBillData[0].veh_no;
            this.reqId = this.ewayBillData[0].request_id;
            this.commonSelectedValues(this.ewayBillData[0]);
          } else {
            this.loader = false;
            alert('Something Went Wrong');
          }
        })
        .catch(error => {
          this.loader = false;
          this.responseMsg = error;
        });
    },
    selectedInvoiceDetails(item) {
      // this.lrNum = item.invoice_num;
      // this.lrDate = new Date(item.invoice_date);
      this.showInvoiceDetailModal = false;
      this.commonSelectedValues(item);
      this.getEwayBillDetails({
        sourceName: this.sourceName.text,
        invoiceId: this.invoiceId
      });
    },
    selectedRadio(item) {
      if (item.status === 'generated') {
        this.generateEwayBillFlag = false;
      } else if (item.status === 'cancelled') {
        this.generateEwayBillFlag = false;
        this.cancelEwayBillFlag = false;
      } else {
        this.generateEwayBillFlag = true;
        this.cancelEwayBillFlag = true;
      }
      this.lrNum = item.trans_doc_no;
      this.lrDate = new Date(item.trans_doc_dt);
      this.transporter.id = item.trans_id;
      this.transporter.name = item.trans_name;
      this.distance = item.distance;
      this.transportationMode.value = item.trans_mode;
      this.transportationMode.text = 'Road';
      this.vehicalType.value = item.veh_type;
      this.vehicalType.text = 'Regular';
      this.vehicleNo = item.veh_no;
      this.commonSelectedValues(item);
    },
    commonSelectedValues(item) {
      this.remarks = item.remarks;
      this.ewayBillNo = item.ewb_no;
      this.invoiceNumber = item.invoice_num;
      this.invoiceId = item.invoice_id;
      this.invoiceDate = item.invoice_date;
      this.fpGstin = item.fp_gstin;
      this.tpGstin = item.tp_gstin;
      this.billFromAdd = item.bill_from;
      this.billToAdd = item.bill_to;
      this.irnNumber = item.irn;
      this.gebId = item.geb_id;
      this.supplier = item.supplier;
      this.customer = item.customer;
      this.billToAddress1 = item.bill_to_address_1;
      this.billToAddress_2 = item.bill_to_address_2;
      this.billToStateCode = item.bill_to_state_code;
      this.billToCity = item.bill_to_city;
      this.billToPincode = item.bill_to_pincode;
      this.supplierAddress1 = item.supplier_address_1;
      this.supplierAddress2 = item.supplier_address_2;
      this.supplierCity = item.supplier_city;
      this.supplierPincode = item.supplier_pincode;
      this.supplierStateCode = item.supplier_state_code;
    },
    maxAllowedDistance(dis) {
      if (dis >= 0 && dis <= 4000) {
        this.distance = dis;
      } else if (dis > 4000) {
        this.distance = 4000;
      } else if (dis < 0) {
        this.distance = 0;
      }
    },
    selectedBranchFun(item) {
      this.sourceName.text = item.text;
    },
    // openValueSetModal(vsetCode) {
    //   this.vsetCode = vsetCode;
    //   this.showValueSetModal = true;
    //   this.setTimeVsetCode = setTimeout(() => {
    //     const vSetData = {
    //       valueSetName: vsetCode,
    //       multiFlag: null
    //     };
    //     this.eventBus.$emit('valueSetCode', vSetData);
    //   }, 0);
    // },
    // selectedvalueSet(item) {
    //   appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST;
    //   if (this.vsetCode === appStrings.VALUESETTYPE.MODE_OF_TRANSPORT) {
    //     this.transportationMode.text = item.value_meaning;
    //     this.transportationMode.value = item.value_code;
    //   } else if (this.vsetCode === appStrings.VALUESETTYPE.VEHICLE_TYPE) {
    //     this.vehicalType.text = item.value_meaning;
    //     this.vehicalType.value = item.value_code;
    //   }
    // },
    // closeValueSetModal() {
    //   this.showValueSetModal = false;
    // },
    closeInvoiceDetailModal(flag) {
      this.showInvoiceDetailModal = flag;
    },
    printReport(){
      const payload = {
            ewb_no: this.ewayBillNo
      }
      this.loader = true;
      this.$store
        .dispatch('assets/ewayBillReport', payload)
        .then(response => {
          if (response.status === 201) {
           this.loader=false;
           this.showAlert = true;
           this.isSuccess = true;
           this.responseMsg = response.data.message;
           this.getEwayBillDetails({
            sourceName: this.sourceName.text,
            invoiceId: this.invoiceId
          });
          } 
        })
        .catch(error => {
          this.loader = false;
          this.responseMsg = error;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
