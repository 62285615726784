import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'InvoiceDetails',
  props:{sourceName:Object},
  watch: {
    currentPage: function() {
      this.getInvoiceDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInvoiceDetails();
    }
  },
  data() {
    return {
      loader: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      payload: {},
      invoiceNumber:null,
      data: [],
      fields: [
        {
          key: 'invoice_id'
        },
        {
          key: 'invoice_num'
        }
      ]
    };
  },
  mounted() {
  },

  methods: {
    getInvoiceDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        source_name:this.sourceName.text,
        invoice_num:this.invoiceNumber
      };
      this.loader = true;
      this.$store.dispatch('assets/getInvoiceNumber', payload)
      .then(response => {
        if (response.status === 200) {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        }
        else {
          this.loader = false;
        }
      });
    },
    clearInvoiceNumber(){
      this.invoiceNumber=null;
    },
    rowSelected(rowData) {
      this.$emit('selectedInvoiceDetails', rowData);
    }
  }
};
